body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes play180 {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -69660px 0px;
  }
}
.LogoAnim {
  animation-duration: 3000ms;
  animation-timing-function: steps(180);
  width: 387px;
  height: 242px;
  scale: 0.5;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  animation-fill-mode: forwards;
}
.LogoAnim.play {
  animation-name: play180;
}
